import React from 'react';
import PrivacyPolicyText from 'components/bespokecomponents/privacyPolicyText';
import dynamic from 'next/dynamic';
import CallToActionWithEmail from './callToActionWithEmail';
import SectionSocialProof from './sectionSocialProof';

// Enums can be found here https://app.hygraph.com/463aa6ac9c0f4817ac75c981c43c398e/master/schema/enumeration/StaticSpecialComponents

// index.tsx
const WrappedMapBigImport = dynamic(() =>
  import('components/fleetmap/wrappedMap')
);
const BuildingMapContainedBigImport = dynamic(() =>
  import('components/buildingmap/buildingMap')
);

const BackgroundMapContainedBigImport = dynamic(() =>
  import('components/shoremap/ShoreMap')
);

const WindMapBigImport = dynamic(() =>
  import('components/windwidget/wrappedWindMap')
);

const WaveMapBigImport = dynamic(() =>
  import('components/wavewidget/wrappedWaveMap')
);

export default function SectionStaticComponent({ section }) {
  switch (section.fancyComponentPicker) {
    case 'animatedmap':
      return <div>not implemented</div>;
    case 'privacy':
      return <PrivacyPolicyText />;
    case 'largefleetmap':
      // @ts-ignore
      return <WrappedMapBigImport />;
    case 'spinningshoremap':
      // @ts-ignore
      return <BackgroundMapContainedBigImport />;
    case 'buildingsinmap':
      // @ts-ignore
      return <BuildingMapContainedBigImport />;
    case 'ctawithemail':
      return <CallToActionWithEmail />;
    case 'windmap':
      // @ts-ignore
      return <WindMapBigImport />;
    case 'wavemap':
      // @ts-ignore
      return <WaveMapBigImport />;
    case 'socialproof':
      // @ts-ignore
      return <SectionSocialProof />;
    default:
      return process.env.NODE_ENV === 'development' ? (
        <h4 className="text-center">
          Unknown component specified: {section.fancyComponentPicker}
        </h4>
      ) : (
        <></>
      );
  }
}
