import React from "react";
import WaveHeader from "components/home/WaveHeader";

export default function SectionWavyHeader({ section }) {
    return (
        <WaveHeader
            title={section.title}
            description={section.description}
            imageurl={section.image.url}
            page={undefined}
        />
    );
}
