import React from "react";
import ParallaxComponent from "components/home/ParallaxComponent";



export default function SectionParallaxDividerWithOptionalContent({ section }) {
    return (
        <ParallaxComponent section={section} />
        );
}
