import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import {
  GradientWrapper,
  StandardWrapper7xl,
} from 'components/LayoutUtilities';
import Image from 'next/image';

const RichTextFormatted = ({ section }) => {
  return (
    <RichText
      content={section.content.raw}
      renderers={{
        h1: ({ children }) => (
          <h1 className="text-text-primary text-display mb-4">
            {children}
          </h1>
        ),
        underline: ({ children }) => (
          <u className="text-brand-pink no-underline">{children}</u>
        ),
        p: ({ children }) => <p className="text-text-primary">{children}</p>,
      }}
    />
  );
};

const ImageAsset = ({ section }) => {
  return (
    <div>
      <Image
        height={section.imageAsset.height}
        width={section.imageAsset.width}
        src={section.imageAsset.url}
        priority={true}
        alt={
          section.imageAsset?.figureCaption
            ? section.imageAsset?.figureCaption
            : 'Hero Image'
        }
      />
    </div>
  );
};

const ImageAndTextWithGradientBackground = ({ section }) => {
  return (
      <StandardWrapper7xl>
        <div>
          {section?.align == 'left' ? (
            <div className="flex flex-col text-center items-center justify-center py-16 gap-4">
              <ImageAsset section={section} />
              <RichTextFormatted section={section} />
            </div>
          ) : (
            <div className="flex flex-col text-center items-center justify-center py-16 gap-4">
              <RichTextFormatted section={section} />
              <ImageAsset section={section} />
            </div>
          )}
        </div>
      </StandardWrapper7xl>
  );
};

export default function ImageAndText({ section }) {
  switch (section?.style) {
    case 's23gradientbackground':
      return <ImageAndTextWithGradientBackground section={section} />;
    case 'anotherstyle':
      return <div />;
    default:
      return <div />;
  }
}
