import React from 'react';
import { HoverCardArticle } from 'components/prosjekter/CardManager';

export default function SectionArticleContentLister({ section }) {
  return (
      <div className="mx-auto py-6 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-4">
        <div className="grid grid-cols-2 gap-8  lg:grid-cols-3 mt-8 text-3xl">
          {section.articles.map(article => (
            <HoverCardArticle
              key={article.id}
              card={{
                link: 'artikler/' + article.slug,
                image: article.squareimage
                  ? article.squareimage.url
                  : article.heroImage.url,
                heading: article.title,
                date: article.relevantdate,
                title:
                  article.abstract.substring(
                    0,
                    Math.min(120, article.abstract.length)
                  ) + '...',
                tags: article.tags.join(' '),
              }}
            />
          ))}
        </div>
      </div>
  );
}
