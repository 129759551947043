import { RichText } from '@graphcms/rich-text-react-renderer';
import React from 'react';

// GraphQL fragment for SectionGridWithRoundImage
export const Q_SectionGridWithRoundImage = `
  ... on SectionGridWithRoundImage {
    id
    title
    description {
      raw
    }
    careerSection {
      employees {
        id
        name
        role
        avatar {
          url(transformation: { document: { output: { format: webp } } })
          figureCaption
        }
      }
    }
  }
`;

export default function SectionGridWithRoundImages({ section }) {
  return (
    <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
      <div className="space-y-8 sm:space-y-12">
        <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
          <h2 className="text-h2 text-text-primary">
            {section?.title}
          </h2>
          <div className="text-text-primary">
            <RichText content={section?.description?.raw} />
          </div>
        </div>
        <ul
          role="list"
          className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6"
        >
          {section?.careerSection?.employees?.map(person => (
            <li key={person.name}>
              <div className="space-y-4">
                <img
                  className="mx-auto h-20 w-20 rounded-full border border-border lg:h-24 lg:w-24"
                  src={person?.avatar?.url}
                  alt={person?.avatar?.figureCaption}
                />
                <div>
                    <p className="text-text-primary">{person.name}</p>
                    <p className="text-text-secondary text-small">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
