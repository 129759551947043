import React from 'react';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

export default function SectionParallax({ section }) {
  return (
    <div className="mt-32">
      <ParallaxProvider>
        <ParallaxBanner
          layers={section?.images?.map((img, index) => ({
            image: img.url,
            speed: index % 2 == 0 ? 30 : -30,
          }))}
          className="aspect-[2/1]"
        >
          <div className="absolute inset-0 flex items-center justify-center"></div>
        </ParallaxBanner>
      </ParallaxProvider>
    </div>
  );
}
