import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import React from 'react';

function ButtonLink({ button }) {
  let link = '/';
  let text = '';

  button.buttonText ? (text = button.buttonText) : '';
  button.title ? (text = button.title) : '';

  button?.dynamicPage?.slug ? (link = button.dynamicPage.slug) : '';
  button.link ? (link = button.link) : '';

  return (
    <Link href={link} legacyBehavior>
      <span className="bg-ilder-pink text-white px-4 py-2 hover:cursor-pointer rounded-full">
        {text}
      </span>
    </Link>
  );
}

export default function Sponsors({ section }) {
  return (
    <div className=" pt-32 pb-32 ">
      <div className="grid grid-cols-1 lg:grid-cols-2 max-w-5xl mx-auto">
        <div className=" mx-auto  max-w-5xl mx-auto">
          <div className="max-w-5xl mx-8 xl:mx-auto">
            <div className="">
              <div className="text-ilder-pink text-xl">{section.topText}</div>
              <div className="text-ilder-darkerblue text-3xl">
                {section.title}
              </div>
              <div className="prose text-lg mt-4 mb-6 text-ilder-darkerblue mr-6">
                <RichText content={section?.description?.raw} />
              </div>
              <ButtonLink button={section.button} />
            </div>
            <div></div>
          </div>
        </div>
        {/* <div className="max-w-7xl mx-auto bg-white flex">
                {section.sponsors.map((sponsor) => (
                    <div className="col-span-1 flex justify-center py-8 px-8 flex-initial">
                        <img
                            className="h-10"
                            src={sponsor?.url}
                            alt={sponsor?.figureCaption}
                        />
                    </div>
                ))}
            </div> */}
        <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
          {section?.sponsors?.map((sponsor, id) => (
            <div
              key={id}
              className="col-span-1 flex justify-center bg-gray-100 py-8 px-8"
            >
              <img
                className="max-h-12"
                src={sponsor?.url}
                alt={sponsor?.figureCaption}
              />
            </div>
          ))}
          {section?.images?.map((sponsor, id) => (
            <div
              key={id}
              className="col-span-1 flex justify-center bg-gray-100 py-8 px-8"
            >
              <img
                className="max-h-12"
                src={sponsor?.url}
                alt={sponsor?.figureCaption}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
