import React from 'react';

export default function Double({ section }) {
  return (
    <div className=" bg-white py-16">
      <div className="text-4xl text-ilder-purple text-center pt-4 pb-12">
        {section.title}
      </div>
      <div className="lg:grid my-auto lg:grid-cols-2 max-w-5xl mx-8 md:mx-16 xl:mx-auto">
        <div
          className={`max-w-7xl my-auto ${
            section.align == 'left' ? 'order-2' : ''
          } ${section.showAssetOnRight ? 'order-2' : ''}`}
        >
          {section?.asset?.url ? (
            <div className="relative my-auto flex">
              <img
                src={section?.asset?.url}
                alt={section?.asset?.figureCaption}
                className="my-auto  w-max align-middle"
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div
          className={`my-auto mx-auto ${
            section.showAssetOnRight ? 'order-1 mr-4' : ''
          }`}
        >
          {section.icons.map(icon => (
            <div key={icon.id} className="my-2">
              <img
                className="inline mr-4 my-2"
                src={icon?.image?.url}
                alt={icon?.image?.figureCaption}
                width={30}
                height={30}
              />
              <span className="lg:text-xl text-ilder-purple">{icon.title}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
