import Image from 'next/image';
import React from 'react';

export default function SectionCardScroller({ section }) {
  return (
    <div className="my-20 max-w-7xl mx-auto grid grid-cols-2 lg:flex">
      {section.images?.map((image, id) => (
        <div
          key={id}
          className={`mx-6  py-2 hover:scale-105 transition duration-50  ${
            id % 2 == 0 ? 'rotate-3 ' : '-rotate-3'
          }`}
        >
          <div className="lg:shadow-xl lg:hover:shadow-2xl transition duration-100 rounded-xl lg:flex">
            <Image
              src={image?.url}
              className={`rounded-xl `}
              height={340}
              width={300}
              alt={'Image for scroller'}
              objectFit="cover"
            />
          </div>
        </div>
      ))}
    </div>
  );
}
