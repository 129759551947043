import Link from 'next/link';
import React, { useState } from 'react';

export default function CallToActionWithEmail() {
  return (
    <div className="bg-background py-16">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="bg-tertiary w-[80%] sm:w-full mx-auto relative isolate flex flex-col gap-8 overflow-hidden px-12 py-16 shadow-2xl rounded-2xl">
          <div>
            <h2 className="text-h2 text-tertiary-foreground mb-2">
              Har du en idé du ønsker å lufte?
            </h2>
            <p className="text-tertiary-foreground">
              Ring oss på{' '}
              <a className="text-link underline" href="tel:+4791900239">
                +47 919 00 239
              </a>{' '}
              for en uforpliktende samtale, eller send oss en epost
              så tar vi kontakt!
            </p>
          </div>
            <Link
                href={`mailto:sales@ilder.no?subject=Nettstedkontakt`}
                legacyBehavior
              >
                <button
                  // disabled={!isValid}
                  className={`rounded-full py-2 px-4 text-small hover:bg-tertiary-foreground hover:text-tertiary text-tertiary-foreground border-tertiary-foreground border`}
                >
                  Send&nbsp;epost
                </button>
              </Link>
        </div>
      </div>
    </div>
  );
}
