import Link from 'next/link';
import React from 'react';

export default function PrivacyPolicyText() {
  return (
    <div className="max-w-7xl mx-4 sm:mx-16 mt-32 xl:mx-auto text-text-primary">
      <div className="mb-12">
        <h1 className="text-h1 mb-4">Privacy Policy</h1>
        <h2 className="text-h2 mb-2">What is a cookie?</h2>
        <div className="inline">
          A cookie is a small piece of data stored in our browser in order to
          keep track of website activities and recognize computers that have
          visited the website before. This information is necessary to enable
          the best user experience of the website. A cookie is not a program and
          does not contain viruses. You can read more about cookies here:{' '}
        </div>
        <span className="inline text-text-link underline hover:no-underline">
          <Link
            href="http://en.wikipedia.org/wiki/HTTP_cookie"
            target="_blank"
            rel="noreferrer"
          >
            en.wikipedia.org/wiki/HTTP_cookie
          </Link>
        </span>
      </div>
      <div className="mb-12">
        <h2 className="text-h2 mb-2">
          This website’s use of cookies
        </h2>
        <div>
          The data collected is used to improve the websites functionality and
          usability based on the users needs and interests. Data is as a general
          rule not shared with any third party. Exceptions from this rule could
          be that a technical solution provider or advisor needs access to the
          data in order to assist Ilder AS in ensuring optimal and continued
          operation of the website. Most browsers are initially set up to accept
          cookies. If you prefer, you can set your browser to reject cookies, or
          to reject third party cookies only. If you reject cookies you may not
          be able to sign in or use other features on websites that rely on
          cookies to enable the user experience.
        </div>
      </div>
      <div>
        <h2 className="text-h2 mb-2">
          The following is a list of cookies used on this website and what they
          are used for:
        </h2>
        <h3 className="text-h3 mb-1.5">Google Analytics</h3>
        <div className="inline">
          Ilder AS uses the analytics platform Google Analytics to study user
          behavior and traffic trends on this website. The data collected is
          used to optimize the user experience and content to better reflect
          user needs and interests. In compliance with Google Privacy Policy
          guidelines no information that personally identifies an individual is
          collected. The data collected is stored on Google servers. You can
          read more about how Google collects and protects data here:{' '}
        </div>
        <span className="inline text-text-link underline hover:no-underline">
          <Link
            href="http://www.google.com/analytics/learn/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
            google.com/analytics/learn/privacy
          </Link>
        </span>
        <div className="mt-2">
          The Google Analytics cookie is a first-party cookie. The cookie is set
          to be automatically deleted after 24 months if you do not return to
          the website. You can reserve yourself against the use Google Analytics
          cookies here:
        </div>
        <span className="inline text-text-link underline hover:no-underline">
          <Link
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noreferrer"
          >
            tools.google.com/dlpage/gaoptout
          </Link>
        </span>
      </div>
    </div>
  );
}
