import React from 'react';
import 'aos/dist/aos.css';
import { RichText } from '@graphcms/rich-text-react-renderer';

export default function SectionSprintCardList({ section }) {
  return (
    <div className="relative mt-32">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {section?.cards?.map((feature, count) => (
              <div key={count} className="pt-6">
                <div className="flow-root bg-background-card rounded-2xl px-6 pb-8 shadow-lg">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-secondary rounded-md shadow-lg">
                        <span className="h-6 w-6 font-bold text-center text-secondary-foreground">
                          {count + 1}
                        </span>
                      </span>
                    </div>
                    <h3 className="text-h3 mt-6 text-text-primary">
                      {feature.title}
                    </h3>
                    <div className="mt-5 prose text-text-secondary">
                      {/* {feature.description} */}
                      <RichText content={feature.description?.raw} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
