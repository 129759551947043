import { RichText } from '@graphcms/rich-text-react-renderer';
import Image from 'next/image';
import React from 'react';

export default function SectionCenteredParagraph({ section }) {
  return (
    <div className="max-w-xl md:mx-auto mt-12 mb-12 mx-4">
      <div className="text-xl text-tertiary font-bold">{section.topText}</div>
      <div className="text-xl mt-8 mb-8">{section.title}</div>
      <div className="text-lg prose">
        <RichText content={section.description?.raw} />
      </div>
      <div className="px-32 mx-auto">
        <Image
          src={section.image?.url}
          width={400}
          height={400}
          alt={section.title}
        />
      </div>
    </div>
  );
}
