import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import Image from 'next/image';

export default function ProjectCard({ section }) {
  return (
    <div className="max-w-7xl mx-auto ">
      <Link href={section.buttonLink} legacyBehavior>
        <div className="lg:grid lg:grid-cols-2 rounded-lg transition duration-500 bg-gray-100 hover:bg-gray-200 my-32  px-4 lg:px-8 py-8 hover:cursor-pointer">
          <div
            className={`my-8 lg:my-auto ${
              section.showImageOnRight
                ? 'order-2 ml-8 rotate-3'
                : 'order-1 mr-8 -rotate-3'
            }`}
          >
            <Image
              width={400}
              height={400}
              src={section?.image?.url}
              alt={section?.image?.figureCaption}
              className="rounded-xl mx-auto h-96 mb-12 object-contain"
            />
          </div>
          <div
            className={`prose text-lg ${
              section.id % 2 == 0 ? 'order-2' : 'order-1'
            }`}
          >
            <div className="text-secondary text-3xl font-semibold underline decoration-2 decoration-brand-pink">
              {section.title}
            </div>
            <div className="text-lg prose mt-6 mb-6">
              <RichText content={section.description?.raw} />
            </div>
            <span className="bg-tertiary text-tertiary-foreground px-12 lg:px-24 -ml-2 py-2 rounded-full hover:cursor-pointer">
              {section.buttonText}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}
