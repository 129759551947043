import React from 'react';
import {
  ResponsiveGridFromOneToTwo,
  StandardHeaderWhite,
} from 'components/LayoutUtilities';
import 'aos/dist/aos.css';
import AnimationBarSubHero from '../animationbars/AnimationBarSubHero';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import Image from 'next/image';

export default function SubHero({ page, section }) {
  if (!page) {
    page = section;
  }
  return (
    <div className="bg-ilder-darkerblue text-white py-16 xl:pb-32 xl:pt-16 lg:pb-12">
      <div className="max-w-5xl mx-8 lg:mx-auto">
        <ResponsiveGridFromOneToTwo
          LeftSide={SubHeroTextBlock({ page })}
          RightSide={SubHeroImage({ page })}
        />
      </div>
    </div>
  );
}

export function SubHeroTextBlock({ page }) {
  return (
    <div className="relative mx-auto">
      <div className="">
        <AnimationBarSubHero />
        <StandardHeaderWhite HeaderText={page.title} />
      </div>
      <div className="mt-10 mb-10">
        <div className="text-lg">
          <RichText content={page.description.raw} />
        </div>
      </div>
    </div>
  );
}

function ButtonLink({ page }) {
  let link = '/';
  let text = '';

  page.buttonLink
    ? (link = page.buttonLink)
    : (link = '/' + page.button.dynamicPage.slug);
  page.buttonText ? (text = page.buttonText) : (text = page.button.buttonText);

  return (
    <Link href={link} className="flex mx-auto" legacyBehavior>

        <div
          className={` bg-ilder-pink
                px-8 mx-auto py-2 items-center justify-center text-md text-white flex rounded-full`}
        >
          <div className="flex">
            <div className={`text-white`}>{text}</div>
          </div>
        </div>

      </Link>
  );
}

export function SubHeroImage({ page }) {
  return (
    <div className="mx-auto my-auto w-full lg:pt-0 ">
      <picture className=" w-full">
        <source
          // @ts-ignore
          srcSet={page?.image?.url}
          type="image/webp"
        />
        <Image
          src={page?.image?.url}
          alt={page?.image?.figureCaption}
          width={600}
          height={300}
        />
      </picture>
      <div className="text-ilder-black flex mt-6">
        <ButtonLink page={page} />
      </div>
    </div>
  );
}
