import { RichText } from '@graphcms/rich-text-react-renderer';
import {
  ButtonLink,
} from 'components/LayoutUtilities';
import React from 'react';

export default function SectionSimpleCentered({ section }) {
  return (
    <div className="bg-background">
      <div className="mx-auto max-w-7xl py-24 px-4 sm:px-6 lg:px-8">
        <div className="text-overline text-tertiary mb-2">{section?.topText}</div>
          <h2 className="text-h2 text-text-primary">
            {section?.title}
          </h2>
          {section?.description ? (
            <div className="max-w-xl">
              <RichText
                content={section?.description?.raw}
                renderers={{
                  p: ({ children }) => (
                    <p className="mt-4 text-text-primary">{children}</p>
                  ),
                  img: ({ src }) => (
                    <img src={src} className="mt-8 mb-4 rounded-2xl" alt="Content image" />
                  ),
                  a: ({ href, title, children }) => (
                    <ButtonLink
                      link={href}
                      text={children}
                      style="pinkborder"
                    />
                  ),
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
    </div>
  );
}
