import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Image from 'next/image';

// GraphQL fragment for SectionTwoColumnsWithImage
export const Q_SectionTwoColumnsWithImage = `
  ... on SectionTwoColumnsWithImage {
    id
    topText
    title
    content {
      raw
    }
    image {
      url(transformation: { document: { output: { format: webp } } })
      figureCaption
    }
  }
`;

export default function SectionTwoColumnsWithImage({ section }) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-tertiary">
              {section?.topText}
            </h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-text-primary sm:text-4xl">
              {section?.title}
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1 ">
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none ">
              <figure className="">
                <div className=" ">
                  <Image
                    className="rounded-lg object-contain shadow-lg "
                    src={section?.image?.url}
                    alt={section?.image?.figureCaption}
                    width={1184}
                    height={876}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none text-lg text-text-secondary max-w-xl prose prose-indigo mx-auto lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <RichText content={section?.content?.raw} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
