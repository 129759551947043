/*
    *** PAGE BUILDER ***
    The PageBuilder Component is responsible for handling which components are rendered,
    based on the typename passed from Hygraph.

    The Typename is directly copied from the tailwindui website when it comes to naming.
    Unsure how it looks? Head over to one of the links, and scroll down to the component
    with the same name as the one you're unsure about.

    As an example, if the __typename === "footer", render a <Footer/> component.
*/

import React from 'react';

// CONTENT: https://tailwindui.com/components/marketing/sections/content-sections
import SectionTwoColumnsWithImagee from './content/sectiontwocolumnswithimage';

// TEAM: https://tailwindui.com/components/marketing/sections/team-sections
import SectionWithImageAndShortParagraph from './team/sectionwithimageandshortparagraph';
import SectionGridWithRoundImages from './team/sectiongridwithroundimages';
import SectionWithLargeImage from './team/sectionwithlargeimages';

// CALL TO ACTION: https://tailwindui.com/components/marketing/sections/cta-sections
import SectionBrandPanelWithOverlappingImage from './calltoaction/sectionbrandpanelwithoverlappingimage';

// INCENTIVES: https://tailwindui.com/components/ecommerce/components/incentives
import ThreeColumnsWithIllustHeader from './incentives/threecolumnwithillustheader';

// GRID LISTS: https://tailwindui.com/components/application-ui/lists/grid-lists
import SectionContactCardsWithSmallPortraits from './gridlists/contactcardswithsmallportraits';

// HEADER: https://tailwindui.com/components/marketing/sections/header
import SectionSimpleCentered from './header/simplecentered';

// PROMO: https://tailwindui.com/components/ecommerce/components/promo-sections
import SectionFullWidthWithBackgroundImage from './promo/fullwidthwithbackgroundimage';

// BLOG: https://tailwindui.com/components/marketing/sections/blog-sections

// CUSTOMIZED (These are customized enough from TailwindUI that they're barely recognizable from their website).
import Location from './__customized/location';
import ProjectCard from './__customized/projectcard';
import SectionSideBySide from './__customized/sidebyside';
import SectionCenteredParagraph from './__customized/centeredparagraph';
import SectionSprintCardList from './__customized/sprintcardlist';
import SectionParallax from './__customized/parallax';
import SectionCardScroller from './__customized/cardscroller';
import SectionPagelister from './__customized/pagelister';
import SectionStaticComponent from './__customized/staticcomponent';
import SectionArticleContentLister from './__customized/articlelister';
import SectionWavyHeader from './__customized/sectionWavyHeader';
import SectionIconsAndImage from './__customized/sectionIconsAndImage';
import SectionWithPinkScrollBarAndCta from './__customized/sectionWithPinkScrollBarAndCta';
import SectionImageTextAndButtonCta from './__customized/sectionImageTextAndButtonCta';
import SectionParallaxDividerWithOptionalContent from './__customized/sectionParallaxDividerWithOptionalContent';
import SectionReferencesWithLogo from './__customized/sectionReferencesWithLogo';
import ImageGroup from './__customized/imageGroups';
import ImageAndText from './__customized/imageAndText';
import ProjectLister from './__customized/projectLister';
import { PWidget } from 'components/hygraph/content';

export function PageBuilder({ component }) {
  /* ========== CONTENT ========== */
  if (component.__typename === 'SectionTwoColumnsWithImage')
    return <SectionTwoColumnsWithImagee section={component} />;

  /* ========== TEAM ========== */
  if (component.__typename === 'SectionWithImageAndShortParagraph')
    return <SectionWithImageAndShortParagraph section={component} />;
  if (component.__typename === 'SectionGridWithRoundImage')
    return <SectionGridWithRoundImages section={component} />;
  if (component.__typename === 'SectionWithLargeImage')
    return <SectionWithLargeImage section={component} />;

  /* ========== CALL TO ACTION ========== */
  if (component.__typename === 'SectionBrandPanelWithOverlappingImage')
    return <SectionBrandPanelWithOverlappingImage section={component} />;

  /* =========== INCENTIVES ============= */
  if (component.__typename === 'SectionThreeColumnWithIllustHeader')
    return <ThreeColumnsWithIllustHeader section={component} />;

  /* =========== GRID LISTS ============= */
  if (component.__typename === 'SectionContactCardsWithSmallPortrait')
    return <SectionContactCardsWithSmallPortraits section={component} />;

  /* =========== HEADER ============= */
  if (component.__typename === 'SectionSimpleCentered')
    return <SectionSimpleCentered section={component} />;

  /* =========== PROMO ============= */
  if (component.__typename === 'SectionFullWidthWithBackgroundImage')
    return <SectionFullWidthWithBackgroundImage section={component} />;

  /* =========== BLOG ============= */
  if (component.__typename === 'SectionThreeColumnCard')
    // this was removed on 2023-04-28 as dead component
    return <div> Dead component </div>;

  /* =========== CUSTOMIZED ============= */
  if (component.__typename === 'SectionCustomLocation')
    return <Location section={component} />;

  if (component.__typename === 'SectionCustomProjectCard')
    return <ProjectCard section={component} />;

  if (component.__typename === 'SectionCustomSideBySide')
    return <SectionSideBySide section={component} />;

  if (component.__typename === 'SectionCustomCenteredParagraph')
    return <SectionCenteredParagraph section={component} />;

  if (component.__typename === 'SectionCustomSprintCardList')
    return <SectionSprintCardList section={component} />;

  if (component.__typename === 'SectionCustomParallax')
    return <SectionParallax section={component} />;

  if (component.__typename === 'SectionCustomCardScroller')
    return <SectionCardScroller section={component} />;

  /* =========== TOR ADDITIONS ============= */
  if (component.__typename === 'SectionPagelister')
    return <SectionPagelister section={component} />;

  if (component.__typename === 'SectionStaticComponent')
    return <SectionStaticComponent section={component} />;

  if (component.__typename === 'SectionArticleContentLister')
    return <SectionArticleContentLister section={component} />;

  /* =========== LANDING PAGE COMPONENTS ============= */

  if (component.__typename === 'SectionWavyHeader')
    return <SectionWavyHeader section={component} />;

  if (component.__typename === 'SectionIconsAndImage')
    return <SectionIconsAndImage section={component} />;

  if (component.__typename === 'SectionWithPinkScrollBarAndCta')
    return <SectionWithPinkScrollBarAndCta section={component} />;

  SectionParallaxDividerWithOptionalContent;
  if (component.__typename === 'SectionImageTextAndButtonCta')
    return <SectionImageTextAndButtonCta section={component} />;

  if (component.__typename === 'SectionParallaxDividerWithOptionalContent')
    return <SectionParallaxDividerWithOptionalContent section={component} />;

  if (component.__typename === 'SectionReferencesWithLogo')
    return <SectionReferencesWithLogo section={component} />;

  // For image groups such as logo clouds, and galleries
  if (component.__typename === 'ImageGroup')
    return <ImageGroup section={component} />;

  if (component.__typename === 'S23ImageAndText')
    return <ImageAndText section={component} />;

  if (component.__typename === 'ProjectLister')
    return <ProjectLister section={component} />;

  if (component.__typename === 'PWidget')
    return <PWidget content={component} />;

  // {component.__typename == "PWidget" ? (
  //     <PWidget content={section} />
  // ) : (
  //     <div />
  // )}

  // If no components, return a div.
  // This ensures there'll be no errors if the page is empty.
  return <div />;
}
