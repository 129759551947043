import React from "react";
import SubHero from "components/home/SubHero";

// Enums can be found here https://app.hygraph.com/463aa6ac9c0f4817ac75c981c43c398e/master/schema/enumeration/StaticSpecialComponents

// Enums privacy, animatedmap

export default function SectionWithPinkScrollBarAndCta({ section }) {
    return <SubHero section={section} />;
}
