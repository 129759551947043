import React, { useRef, useState, useEffect } from 'react';
import { StandardWrapper7xl } from 'components/LayoutUtilities';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import Image from 'next/image';
import { StandardPinkUpperCaseAboveTheFoldHeader } from '@componentsLayoutUtilities';

const testimonials = [
  {
    logoSrc:
      'https://media.graphassets.com/output=format:webp/vD38Pr1hTAKcPfIL4gA1',
    content:
      'Ilder analyserte behovet til våre kunder, prioriterte og gjorde løsningen så enkel at selv «bestemor» forstår det.',
    personImage:
      'https://media.graphassets.com/annBzaRqTAmPGZ6etBA5?_gl=1*1xmiz9w*_ga*NTQ5MDM5NzcxLjE2NjU5OTI4MTY.*_ga_G6FYGSYGZ4*MTcwNTA2MzQyNi4yNzkuMS4xNzA1MDYzNDQ3LjM5LjAuMA..',
    personName: 'Camilla Frydenbø',
    position: 'CEO i Lyll AS',
  },
  {
    logoSrc:
      'https://media.graphassets.com/output=format:webp/aF5AYTOSTYqkTd6Y1dIp',
    content:
      'llder har vært en uvurderlig partner, og har bidratt til å skape et solid og fleksibelt system med et enkelt brukergrensesnitt.',
    personImage:
      'https://media.graphassets.com/Y7qRNV2QTRmSwLv6YXlp?_gl=1*jbiewd*_ga*NTQ5MDM5NzcxLjE2NjU5OTI4MTY.*_ga_G6FYGSYGZ4*MTcwNTA2MzQyNi4yNzkuMS4xNzA1MDYzNDQ3LjM5LjAuMA..',
    personName: 'Jan Tore Grindheim',
    position: 'CEO i Fonn',
  },
  {
    logoSrc:
      'https://media.graphassets.com/output=format:webp/WpBxgSc1QMidnyPpFPob',
    content:
      'Ilder består av en framoverlent gjeng som er svært kompetente, og bryr seg virkelig om å finne gode løsninger på våre problemstillinger.',
    personImage:
      'https://media.graphassets.com/mOMA7tFCRCC2K1PzTNsT?_gl=1*d5eawq*_ga*NTQ5MDM5NzcxLjE2NjU5OTI4MTY.*_ga_G6FYGSYGZ4*MTcwNTA2MzQyNi4yNzkuMS4xNzA1MDYzNDQ3LjM5LjAuMA..',
    personName: 'Eyvind Bohne-Kjersem',
    position: 'leder i Eviny Mobil Energi',
  },
  {
    logoSrc:
      'https://media.graphassets.com/output=format:webp/FFQIuxKqQa40DfwVZIqF',
    content:
      'Ilder har fokus på å forstå hvilke behov vi som brukere har. Resultatet er Plug Insight, en software som er første stoppested for alle mine øyeblikks behov.',
    personImage:
      'https://media.graphassets.com/4jErORPXSYCHZEQCk67N?_gl=1*1xmiz9w*_ga*NTQ5MDM5NzcxLjE2NjU5OTI4MTY.*_ga_G6FYGSYGZ4*MTcwNTA2MzQyNi4yNzkuMS4xNzA1MDYzNDQ3LjM5LjAuMA..',
    personName: 'Bengt Vestvik-Bruknapp',
    position: 'Teknisk drift i Plug AS',
  },
  //   {
  //     logoSrc:
  //       'https://media.graphassets.com/output=format:webp/g2KlpeORIOFGqMhqGyCZ',
  //     content:
  //       'llder har utvikla ein effektiv plattform som spissar informasjon om våre medlemsbedrifter på ulike nettstader.Ulike målgrupper kan sjå informasjon tilpassa dei, medan me kjapt oppdaterer informasjonen på ein stad. Samarbeidet med ilder har vore avgjerande for å få til denne løysinga, som gjev stor verdi for både oss og medlemsbedriftene våre.',
  //     personImage:
  //       'https://media.licdn.com/dms/image/C4D03AQFbZ469-epPxg/profile-displayphoto-shrink_800_800/0/1658667871174?e=1703721600&v=beta&t=ttnpRvFGh3fq-Rt47lBML8veWi8cJMFdfxLM6XZTWos',
  //     personName: 'Nina Ingvaldsen',
  //     position: 'Dagleg leiar, Bømlo næringsråd',
  //   },
];
export const Carousel = ({ children }) => {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [gyroAngle, setGyroAngle] = useState(null); // to store the gyroscope angle

  const checkScroll = () => {
    if (!scrollContainerRef.current) return;

    const element = scrollContainerRef.current;

    setCanScrollLeft(element.scrollLeft > 0);
    setCanScrollRight(
      element.scrollLeft < element.scrollWidth - element.clientWidth
    );
  };

  useEffect(() => {
    const handleOrientation = event => {
      const beta = event.beta; // range [-180, 180]. You can use this for vertical orientation
      const gamma = event.gamma; // range [-90, 90]. You can use this for horizontal orientation
      setGyroAngle(gamma); // set the gamma value to the state
      if (scrollContainerRef.current) {
        const scrollWidth = scrollContainerRef.current.scrollWidth;
        const clientWidth = scrollContainerRef.current.clientWidth;
        // mapping gamma value [-90, 90] to [0, scrollWidth - clientWidth]
        const scrollPos = ((gamma + 90) / 180) * (scrollWidth - clientWidth);
        scrollContainerRef.current.scrollLeft = scrollPos;
      }
    };

    // Check for support and add the event listener
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', handleOrientation);
    } else {
    }

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, []);

  useEffect(() => {
    checkScroll();
    // Add event listener to check the scroll position after a scroll event
    scrollContainerRef.current?.addEventListener('scroll', checkScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollContainerRef.current?.removeEventListener('scroll', checkScroll);
    };
  }, []);

  const handleScroll = direction => {
    if (!scrollContainerRef.current) return;

    const scrollAmount = direction === 'left' ? -300 : 300;
    const element = scrollContainerRef.current;

    if (direction === 'left') {
      element.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    } else if (direction === 'right') {
      element.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }

    checkScroll();
  };

  function requestOrientationPermission() {
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', e => {
              // Your handler function here
              console.log("we're in ios");
            });
          }
        })
        .catch(console.error);
    } else {
      // Handle regular non iOS 13+ devices
      window.addEventListener('deviceorientation', e => {
        // Your handler function here
        console.log('we are here');
      });
    }
  }

  return (

    <StandardWrapper7xl>
    <div className="relative flex flex-col items-center justify-center overflow-hidden">

      <div className=" md:hidden">
        {/* <button
          onClick={() => requestOrientationPermission()}
          className={`mb-4 flex-none rounded-full py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white bg-ilder-pink hover:bg-gray-100 hover:text-ilder-pink`}
        >
          Prøv gyro-scrolling?
        </button> */}
      </div>
      {canScrollLeft && (
        <button
          className="absolute left-2 z-10  rounded-full bg-background p-2"
          onClick={() => handleScroll('left')}
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2929 3.29289C13.6834 2.90237 14.3166 2.90237 14.7071 3.29289C15.0976 3.68342 15.0976 4.31658 14.7071 4.70711L7.41421 12L14.7071 19.2929C15.0976 19.6834 15.0976 20.3166 14.7071 20.7071C14.3166 21.0976 13.6834 21.0976 13.2929 20.7071L5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L13.2929 3.29289Z"
              fill="none"
              stroke="#E53489"
              strokeWidth="4"
            ></path>
          </svg>
        </button>
      )}

      <div className="w-full">
        <div
          ref={scrollContainerRef}
          className="scrollbar-none flex w-full snap-mandatory snap-x scroll-px-10 gap-4 overflow-hidden overflow-x-scroll px-2 sm:px-10"
        >
          {children}
        </div>
      </div>

      {canScrollRight && (
        <button
          className="absolute right-2 z-10 rounded-full bg-background p-2 focus:outline-none"
          onClick={() => handleScroll('right')}
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7071 3.29289C10.3166 2.90237 9.68342 2.90237 9.29289 3.29289C8.90237 3.68342 8.90237 4.31658 9.29289 4.70711L16.5858 12L9.29289 19.2929C8.90237 19.6834 8.90237 20.3166 9.29289 20.7071C9.68342 21.0976 10.3166 21.0976 10.7071 20.7071L18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L10.7071 3.29289Z"
              fill="none"
              stroke="#E53489"
              strokeWidth="4"
            ></path>
          </svg>
        </button>
      )}
    </div>
    </StandardWrapper7xl>
  );
};

export default function SectionSocialProof() {
  return (
    <div>
      <Carousel>
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="w-[90%] shrink-0 snap-center snap-always sm:w-[60%] md:w-[50%] bg-background-card p-8 rounded-2xl border"
          >
            <img
              className="h-12 self-start mix-blend-difference grayscale"
              src={testimonial.logoSrc}
              alt=""
            />
            <figure className="mt-10 flex flex-auto flex-col justify-between">
              <blockquote className="text-text-primary">
                <p>{testimonial.content}</p>
              </blockquote>
              <figcaption className="mt-10 flex items-center gap-x-6">
                <img
                  className="h-14 w-14 rounded-full bg-gray-50"
                  src={testimonial.personImage}
                  alt=""
                />
                <div className="text-base">
                  <div className="text-text-primary">
                    {testimonial.personName}
                  </div>
                  <div className="mt-1 text-small text-tertiary">
                    {testimonial.position}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
