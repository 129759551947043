import { StandardWrapper7xl } from 'components/LayoutUtilities';
import React from 'react';

function LogoCloud23({ images }) {
  return (
      <StandardWrapper7xl>
        <div className="mx-auto px-4 grid grid-cols-2 items-center gap-x-8 gap-y-6 sm:grid-cols-4 lg:mx-0 lg:grid-cols-5">
          {images?.map((image, id) => (
            <div key={id} className="flex justify-center p-4 h-16">
              <img
                className="self-center mix-blend-difference grayscale max-h-12"
                src={image?.url}
                alt={image?.figureCaption ? image?.figureCaption : 'Logo'}
              />
            </div>
          ))}
        </div>
      </StandardWrapper7xl>
  );
}

export default function ImageGroup({ section }) {
  switch (section.style) {
    case 'logocloud23':
      return <LogoCloud23 images={section?.imageAssets} />;
    case 'anotherstyle':
      return <LogoCloud23 images={section?.imageAssets} />;
    default:
      return <LogoCloud23 images={section?.imageAssets} />;
  }
}
