import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import React from 'react';
import Obfuscate from 'react-obfuscate';

export default function SectionFullWidthWithBackgroundImage({ section }) {
  return (
    <div className="relative py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={section?.image?.url}
          alt={section?.image?.figureCaption}
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 bg-black bg-opacity-50"
      />
      <div className="relative mx-auto flex gap-2 flex-col items-center text-center">
        <h2 className="text-h2 text-primary-foreground">
          {section?.title}
        </h2>
        <div className="max-w-xl text-primary-foreground">
          <RichText content={section?.description?.raw} />
        </div>
        {section?.link ? localNavigation(section) : <div />}

        {section?.email ? email(section) : <div />}
      </div>
    </div>
  );
}

function localNavigation(section) {
  return (
    <Link
      href={section?.link?.buttonLink}
      className="mt-4 block w-full rounded-full border border-transparent  py-3 px-8 bg-secondary text-secondary-foreground hover:cursor-pointer sm:w-auto"
      legacyBehavior
    >
      {section?.link?.buttonText}
    </Link>
  );
}

function email(section) {
  return (
    <div className="mt-4 block w-full rounded-full border border-transparent  py-3 px-8 bg-secondary hover:bg-secondary-hover text-secondary-foreground hover:cursor-pointer sm:w-auto">
      <Obfuscate
        email={section?.email?.email}
        headers={{
          subject: 'Contact via ilder.no',
        }}
      />
    </div>
  );
}
