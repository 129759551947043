import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';

import React from 'react';
// @ts-ignore
import Obfuscate from 'react-obfuscate';

export default function SectionWithLargeImage({ section }) {
  return (
    <div className="mt-12">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-h2 text-text-primary">
              {section.title}
            </h2>
            <div className="text-xl text-text-secondary">
              <RichText content={section?.description?.raw} />
            </div>
          </div>
          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            {section.careerSection?.employees?.map(person => (
              <li key={person.name}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-3">
                    <img
                      className="rounded-2xl object-fill shadow-lg"
                      src={person?.avatar?.url}
                      alt={person?.avatar?.figureCaption}
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="space-y-1">
                      <div className="flex justify-between">
                        <h4 className="inline text-h4 text-text-primary">{person.name}</h4>
                        {person.contactInfo?.linkedIn && (
                          <span className="inline text-text-link cursor-pointer">
                            <Link
                              href={person.contactInfo?.linkedIn}
                              target="_blank"
                              className="inline"
                              rel="noreferrer"
                              legacyBehavior
                            >
                              <div>
                                <span className="sr-only">LinkedIn</span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </Link>
                          </span>
                        )}
                      </div>
                      <p className="text-text-secondary">{person.role}</p>
                    </div>
                    <ul role="list" className="">
                      {person.contactInfo?.phoneNumber ? (
                        <li>
                          <span className="text-text-primary">
                            {person.contactInfo.phoneNumber}
                          </span>
                        </li>
                      ) : (
                        <div />
                      )}
                      {person.contactInfo?.email ? (
                        <li className="text-text-link underline hover:no-underline">
                          <Obfuscate
                            email={person?.contactInfo?.email}
                            headers={{
                              subject: 'Mail fra Ilder.no',
                            }}
                          />
                        </li>
                      ) : (
                        <div />
                      )}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
