import React, { useEffect } from 'react';

import { useRef } from 'react';

var pi = Math.PI;
var pi2 = 2 * Math.PI;

// For reference https://codepen.io/bsehovac/pen/LQVzxJ
// For animation of waves on frontpage
class SingleWave {
  constructor(Waves) {
    var Wave = this;
    var speed = Waves.options.speed;
    Wave.Waves = Waves;
    Wave.Lines = [];

    Wave.angle = [rnd(pi2), rnd(pi2), rnd(pi2), rnd(pi2)];

    Wave.speed = [
      rnd(speed[0], speed[1]) * rnd_sign(),
      rnd(speed[0], speed[1]) * rnd_sign(),
      rnd(speed[0], speed[1]) * rnd_sign(),
      rnd(speed[0], speed[1]) * rnd_sign(),
    ];
  }
  /* eslint-disable */
  // Code block where ESLint errors should be ignored
  update = function () {
    var Wave = this;
    var Lines = Wave.Lines;
    var color = Wave.Waves.color;

    Lines.push(new Line(Wave, color));

    if (Lines.length > Wave.Waves.options.width) {
      Lines.shift();
    }
  };
  /* eslint-enable */

  draw = function () {
    var Wave = this;
    var Waves = Wave.Waves;

    var ctx = Waves.ctx;
    var radius = Waves.radius;
    var radius3 = radius / 3;
    var x = Waves.centerX;
    var y = Waves.centerY;
    var rotation = dtr(Waves.options.rotation);
    var amplitude = Waves.options.amplitude;
    var debug = Waves.options.debug;

    var Lines = Wave.Lines;

    each(Lines, function (line, i) {
      if (debug && i > 0) return;

      var angle = line.angle;

      var x1 = x - radius * Math.cos(angle[0] * amplitude + rotation);
      var y1 = y - radius * Math.sin(angle[0] * amplitude + rotation);
      var x2 = x + radius * Math.cos(angle[3] * amplitude + rotation);
      var y2 = y + radius * Math.sin(angle[3] * amplitude + rotation);
      var cpx1 = x - radius3 * Math.cos(angle[1] * amplitude * 2);
      var cpy1 = y - radius3 * Math.sin(angle[1] * amplitude * 2);
      var cpx2 = x + radius3 * Math.cos(angle[2] * amplitude * 2);
      var cpy2 = y + radius3 * Math.sin(angle[2] * amplitude * 2);

      ctx.strokeStyle = debug ? '#fff' : line.color;

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, x2, y2);
      ctx.stroke();
    });
  };
}

class WaveClass {
  constructor(canvasRef, context, holderRef) {
    var Waves = this;
    Waves.options = {
      resize: true,
      rotation: 60,
      waves: 9,
      width: 128,
      hue: [7, 7],
      amplitude: 0.8,
      background: false, //just messes things up
      preload: true,
      speed: [0.004, 0.008],
      debug: false,
      fps: false,
    };

    Waves.waves = [];

    Waves.holder = holderRef;
    Waves.canvas = canvasRef;
    Waves.ctx = context;

    Waves.hue = Waves.options.hue[0];
    Waves.hueFw = true;
    //   Waves.stats = new Stats();

    Waves.resizeCanvas();
    Waves.init(Waves.options.preload);

    if (Waves.options.resize)
      window.addEventListener(
        'resize',
        function () {
          Waves.resizeCanvas();
        },
        false
      );
    this.width = undefined;
    this.height = undefined;
    this.scale = undefined;
    this.radius = undefined;
    this.centerX = undefined;
    this.centerY = undefined;
  }

  init = function (preload) {
    var Waves = this;
    var options = Waves.options;

    for (var i = 0; i < options.waves; i++)
      Waves.waves[i] = new SingleWave(Waves);

    if (preload) Waves.preload();
  };

  preload = function () {
    var Waves = this;
    var options = Waves.options;

    for (var i = 0; i < options.waves; i++) {
      Waves.updateColor();
      for (var j = 0; j < options.width; j++) {
        Waves.waves[i].update();
      }
    }
  };

  renderWaves = function () {
    var Waves = this;
    var ctx = Waves.ctx;
    // @ts-ignore
    //  var options = Waves.options;

    Waves.updateColor();
    Waves.clear();

    // @ts-ignore
    each(Waves.waves, function (wave, i) {
      wave.update();
      wave.draw();
    });
  };

  animate = function () {
    var Waves = this;

    Waves.render();
  };

  clear = function () {
    var Waves = this;
    Waves.ctx.clearRect(0, 0, Waves.width, Waves.height);
  };

  background = function () {
    var Waves = this;
    var ctx = Waves.ctx;

    var gradient = Waves.ctx.createLinearGradient(0, 0, 0, Waves.height);
    gradient.addColorStop(0, '#345');
    gradient.addColorStop(1, Waves.color);

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, Waves.width, Waves.height);
  };

  resizeCanvas() {
    var Waves = this;
    var width = Waves.holder.offsetWidth;
    var height = Waves.holder.offsetHeight;

    // Only update canvas size and other values if they have changed
    if (width !== Waves.width || height !== Waves.height) {
      Waves.scale = window.devicePixelRatio || 1;
      Waves.width = width * Waves.scale;
      Waves.height = height * Waves.scale;
      if (Waves.canvas.width !== Waves.width) {
        Waves.canvas.width = Waves.width;
        Waves.canvas.style.width = width + 'px';
      }
      if (Waves.canvas.height !== Waves.height) {
        Waves.canvas.height = Waves.height;
        Waves.canvas.style.height = height + 'px';
      }

      // Calculate radius and center coordinates using faster formula
      Waves.radius = Math.max(Waves.width, Waves.height) / 2;
      Waves.centerX = Waves.width / 2;
      Waves.centerY = Waves.height / 2;
    }
  }

  updateColor() {
    let Waves = this;

    // Check if hue is at upper or lower bound
    if (
      Waves.hue >= Waves.options.hue[1] ||
      Waves.hue <= Waves.options.hue[0]
    ) {
      // Change direction if at bound
      Waves.hueFw = !Waves.hueFw;
    }

    // Increment or decrement hue based on direction
    Waves.hue += Waves.hueFw ? 0.01 : -0.01;

    // Calculate color values
    let a = Math.floor(127 * Math.sin(0.3 * Waves.hue + 0) + 128);
    let b = Math.floor(127 * Math.sin(0.3 * Waves.hue + 2) + 128);
    let c = Math.floor(127 * Math.sin(0.3 * Waves.hue + 4) + 128);

    Waves.color = 'rgba(' + a + ',' + b + ',' + c + ', 0.2)';
  }

  update = function () {
    var Wave = this;
    var Lines = Wave.Lines;
    var color = Wave.Waves.color;
    Lines.push(new Line(Wave, color));

    if (Lines.length > Wave.Waves.options.width) {
      Lines.shift();
    }
  };

  draw = function (context) {
    var Wave = this;
    var Waves = Wave.Waves;

    var ctx = context;
    var radius = Waves.radius;
    var radius3 = radius / 3;
    var x = Waves.centerX;
    var y = Waves.centerY;
    var rotation = dtr(Waves.options.rotation);
    var amplitude = Waves.options.amplitude;
    var debug = Waves.options.debug;

    var Lines = Wave.Lines;

    // Calculate x1, y1, x2, y2, cpx1, cpy1, cpx2, cpy2 outside of the loop
    var x1 = x - radius * Math.cos(angle[0] * amplitude + rotation);
    var y1 = y - radius * Math.sin(angle[0] * amplitude + rotation);
    var x2 = x + radius * Math.cos(angle[3] * amplitude + rotation);
    var y2 = y + radius * Math.sin(angle[3] * amplitude + rotation);
    var cpx1 = x - radius3 * Math.cos(angle[1] * amplitude * 2);
    var cpy1 = y - radius3 * Math.sin(angle[1] * amplitude * 2);
    var cpx2 = x + radius3 * Math.cos(angle[2] * amplitude * 2);
    var cpy2 = y + radius3 * Math.sin(angle[2] * amplitude * 2);

    for (let i = 0; i < Lines.length; i++) {
      var line = Lines[i];
      var angle = line.angle;

      ctx.strokeStyle = debug ? '#fff' : line.color;

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, x2, y2);
      ctx.stroke();
    }
  };
}

function Line(Wave, color) {
  var Line = this;

  var angle = Wave.angle;
  var speed = Wave.speed;

  // Calculate angle values
  Line.angle = [];
  for (var i = 0; i < angle.length; i++) {
    Line.angle.push(Math.sin((angle[i] += speed[i])));
  }

  Line.color = color;
}
// Optimization
// https://www.freecodecamp.org/news/how-to-optimize-your-javascript-apps-using-loops-d5eade9ba89f/
function each(items, callback) {
  for (var i = items.length; i--; ) {
    callback(items[i], i);
  }
}

function dtr(deg) {
  return (deg * pi) / 180;
}

function rnd(a, b) {
  if (arguments.length == 1) return Math.random() * a;
  return a + Math.random() * (b - a);
}

function rnd_sign() {
  return Math.random() > 0.5 ? 1 : -1;
}

const WaveRenderer = () => {
  const canvasRef = useRef(null);
  const holderRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore
    const holder = holderRef.current;
    const context = canvas.getContext('2d');
    let animationFrameId;
    let timeoutId;

    // Move animwaves object outside of useEffect() callback
    const animwaves = new WaveClass(canvas, context, holder);

    setTimeout(() => {
      animwaves.resizeCanvas();
    }, 0);

    const render = () => {
      // Use more efficient rendering algorithm
      animwaves.renderWaves();

      timeoutId = setTimeout(() => {
        animationFrameId = window.requestAnimationFrame(render);
      }, 60);
    };
    render();

    return () => {
      clearTimeout(timeoutId);
      window.cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div
      className="w-full h-full absolute object-center object-cover"
      ref={holderRef}
    >
      <canvas ref={canvasRef} />
    </div>
  );
};

export default function WaveCanvas() {
  return <WaveRenderer />;
}
