import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import React from 'react';

export default function SectionBrandPanelWithOverlappingImage({ section }) {
  return (
    <div className="relative py-16">

      <div className="mx-auto max-w-7xl bg-ildergradient-purple23 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
            <div
              className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
              aria-hidden="true"
            />
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                <img
                  className="rounded-3xl object-cover object-center shadow-2xl"
                  src={section?.image?.url}
                  alt={section?.image?.figureCaption}
                />
              </div>
            </div>
          </div>

          <div className="relative bg-background-card lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
            <div className="relative mx-auto max-w-md space-y-6 py-12 px-4 sm:max-w-3xl sm:py-16 sm:px-6 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
              <h2
                className="text-h2 text-text-primary"
                id="join-heading"
              >
                {section?.title}
              </h2>
              <div className="text-text-primary">
                {section?.description ? (
                  <RichText content={section?.description?.raw} />
                ) : (
                  ''
                )}
              </div>
              {section?.buttonLink ? (
                <span className="block w-full rounded-full border border-tertiary py-3 px-5 text-center text-tertiary-foreground bg-tertiary hover:bg-tertiary-hover sm:inline-block sm:w-auto">
                  <Link href={section?.buttonLink} legacyBehavior>
                    {section?.buttonText}
                  </Link>
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
