import { RichText } from "@graphcms/rich-text-react-renderer";
import React from "react";

export default function ThreeColumnsWithIllustrationsAndSplitHeader({
    section,
}) {
    return (
        <div className="bg-gray-50">
            <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
                <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                    <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
                        <div>
                            <h2 className="text-4xl font-bold tracking-tight text-text-primary">
                                {section?.title}
                            </h2>
                            <div className="mt-4 prose text-lg text-text-secondary">
                                <RichText content={section?.description?.raw} />
                            </div>
                        </div>
                        <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg bg-gray-100">
                            <img
                                src={section?.image?.url}
                                alt="Generic Image"
                                className="object-cover object-center"
                            />
                        </div>
                    </div>
                    <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
                        {section?.incentive?.map((incentive) => (
                            <div
                                key={incentive.id}
                                className="sm:flex lg:block mx-auto"
                            >
                                <div className="sm:flex-shrink-0">
                                    <img
                                        className="h-16 mx-auto"
                                        src={incentive?.image?.url}
                                        alt={incentive?.image?.figureCaption}
                                    />
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                    <h3 className="text-sm font-medium text-text-primary">
                                        {incentive.title}
                                    </h3>
                                    <div className="mt-2 text-sm text-text-secondary">
                                        <RichText
                                            content={incentive.description?.raw}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
