import React, { useEffect } from 'react';

import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import {
  StandardPinkUpperCaseAboveTheFoldHeader,
  ButtonLink,
} from 'components/LayoutUtilities';
import aos from 'aos';
import 'aos/dist/aos.css';
import { RichText } from '@graphcms/rich-text-react-renderer';

export default function ParallaxComponent({ section }) {
  return (
    <div>
      <ParallaxProvider>
        <ParallaxBanner layers={[{ image: section.image?.url, speed: -20 }]}>
          <div className="grid grid-cols-1 lg:grid-cols-2 max-w-5xl mx-8 sm:mx-8 lg:mx-auto">
            <div
              className={`${
                section?.align == 'left' ? 'col-start-2' : 'col-start-1'
              }`}
            >
              <TextBlock
                topText={section?.pinkheader}
                description={section?.text}
                button={
                  section.button ? (
                    <ButtonLink button={section?.button} style={'pinkborder'} />
                  ) : (
                    ''
                  )
                }
              />{' '}
            </div>
          </div>
        </ParallaxBanner>
      </ParallaxProvider>
    </div>
  );
}

export function TextBlock({ topText, description, button }) {
  useEffect(() => {
    aos.init({});
  }, []);
  return (
    <div
      className="bg-background-card rounded-xl py-4 my-16"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
    >
      <div className="p-8">
        <div className="text-overline text-tertiary">{topText}</div>
        <div className="text-text-primary not-prose prose-headings:text-text-primary prose-a:text-link">
          <RichText
            content={description?.raw}
            renderers={{
              p: ({ children }) => (
                <p className="text-text-primary py-2">{children}</p>
              ),
              table_header_cell: ({ children }) => (
                <th className="text-bold text-text-primary">
                  {children}
                </th>
              ),
              table_head: ({ children }) => (
                <thead className="text-text-primary border-0 text-center">
                  {children}
                </thead>
              ),
              table_row: ({ children }) => (
                <tr className="text-center">{children}</tr>
              ),
            }}
          />
          {button}
        </div>
      </div>
    </div>
  );
}
