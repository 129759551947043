import Image from 'next/image';
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

/*
Schema here
https://app.hygraph.com/463aa6ac9c0f4817ac75c981c43c398e/master/schema/component/SectionPagelister
      */

// GraphQL fragment for SectionPagelister
export const Q_SectionPagelister = `
  ... on SectionPagelister {
    formatListing
    dynamicPages {
      ogData {
        title
        image {
          url(transformation: { image: { resize: { fit: crop, height: 800, width: 800 } } document: { output: { format: webp } } })
        }
        description
      }
      slug
    }
  }
`;

export default function SectionPagelister({ section }) {
  const router = useRouter();
  return (
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
          {section.dynamicPages.map(page => (
            <Link
              key={page.slug}
              href={`${router.asPath}/${page.slug}`}
              legacyBehavior
            >
              <div className="group relative cursor-pointer">
                <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-2xl border border-border">
                  <Image
                    src={page.ogData.image.url}
                    alt={page.ogData.title}
                    layout="fill"
                    className="object-cover object-center"
                  />
                  <div
                    className="flex items-end p-4 opacity-0 group-hover:opacity-100"
                    aria-hidden="true"
                  >
                    <div className="w-full rounded-full bg-tertiary bg-opacity-75 py-2 px-4 text-center text-sm font-medium text-tertiary-foreground backdrop-blur backdrop-filter">
                      Les mer
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-between space-x-8">
                  <h4 className="text-h4 text-text-primary">
                    <span aria-hidden="true">{page.ogData.title}</span>
                  </h4>
                </div>
                <p className="mt-1 text-small text-text-secondary">
                  {page.ogData.category}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
  );
}
