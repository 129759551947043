import React from 'react';
import Link from 'next/link';
import dayjs from 'dayjs';

export function HoverCardArticle({ card }) {

  return (
    <div className="cursor-pointer rounded-2xl bg-background-card">
      <Link href={card?.link} className="group cursor-pointer">
      <>
          <div className="w-full items-center justify-center overflow-hidden rounded-2xl">
            <img
              src={card.image}
              className="w-full h-full transition-all duration-500 ease-in-out transform hover:scale-125"
              alt={card.tags}
            />
          </div>{' '}
          <div className="px-4 py-4">
            {card.heading ? (
              <h4 className="text-h4 text-text-primary">
                {card.heading}
              </h4>
            ) : (
              ''
            )}
            {/* {card.date ? (
              <p className="text-small text-text-secondary">
                <time dateTime={card.date}>
                  {dayjs(card.date).format('YYYY')}
                </time>
              </p>
            ) : (
              ''
            )} */}
            <div className="text-text-secondary text-semibold text-sm py-1 my-2">
                {card.tags}
            </div>
            <div className="text-small text-text-secondary">
              {card.title}
            </div>
          </div>
          </>
      </Link>
    </div>
  );
}

export default HoverCardArticle;
