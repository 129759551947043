import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import Image from 'next/image';

export default function SectionSideBySide({ section }) {
  return (
    <div className={`pt-12 pb-12 `}>
      <div
        className={`md:grid md:grid-cols-2  pt-12 pb-12 max-w-5xl mx-8 my-auto lg:mx-auto `}
      >
        <div
          className={`max-w-sm mb-12 md:mb-0 ${
            section.showImageOnRight ? 'order-1' : 'order-2 md:mx-auto'
          }`}
        >
          <div className="prose">
            <div className="text-overline text-tertiary mb-2">
              {section?.topText}
            </div>
            <h2 className={`text-h2 text-text-primary mt-2`}>{section?.title}</h2>
            <div
              className={`prose ${
                section.showBlueBackground ? 'text-secondary-foreground' : 'text-text-primary'
              }`}
            >
              <RichText content={section?.description?.raw} />
            </div>
          </div>
          <div className="mt-4">
            {section?.button ? (
              <span className="hover:cursor-pointer bg-secondary -ml-2 rounded-full px-4 py-2 text-secondary-foreground text-lg ">
                <Link href={section?.button?.link} legacyBehavior>
                  {section?.button?.title}
                </Link>
              </span>
            ) : (
              <div />
            )}
            <div className="text-xl text-secondary">{section.subtitle}</div>
          </div>
        </div>
        <div
          className={` my-auto mx-auto  ${
            section.showImageOnRight ? 'order-2' : 'order-1'
          }`}
        >
          <div>
            <div className="my-auto flex align-middle ">
              {/*TODO: Fix video */}
              {section?.isVideo ? (
                <video src={section.image.url} autoPlay loop muted />
              ) : section?.image?.url ? (
                <Image
                  src={section?.image?.url}
                  width={600}
                  height={600}
                  alt={'Image'}
                  objectFit="contain"
                  className="rounded-2xl"
                />
              ) : (
                'Missing image'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
