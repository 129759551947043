import React from 'react';
import WaveCanvas from './WaveCanvas';
import Image from 'next/image';
const { detect } = require('detect-browser');
const browser = detect();

function isBrowserFirefox() {
  if (browser && browser.name && browser.name == 'firefox') {
    return <div />;
  }
  return <WaveCanvas />;
}

export default function WaveHeader({ page, title, description, imageurl }) {
  return (
    <div>
      <div className="relative overflow-hidden py-2 sm:py-4 md:py-6 lg:py-12 bg-ilder-darkerblue">
        {isBrowserFirefox()}

        <div className="absolute inset-0">
          <div className="w-full h-full">
            <div className="bg-ilder-darkerblue h-2/5"></div>
            <div className="bg-gradient-to-b from-ilder-darkerblue h-1/5"></div>
            <div className="h-1/5"></div>
            <div className="bg-gradient-to-t from-ilder-darkerblue h-1/5"></div>
          </div>
        </div>
        <div className="relative  mb-16 max-w-7xl mx-auto pb-16">
          <h1 className="tracking-tight text-gray-900 text-center ">
            <span className="block font-semibold text-white tracking-widest  mt-8 text-4xl md:text-5xl lg:text-6xl xl:text-7xl pb-2 md:pb-6 lg:pb-8">
              {page?.title ? page?.title : title}
            </span>
            <span className="block mt-4  tracking-wide text-ilder-pink font-normal text-medium lg:text-2xl">
              {page?.description ? page?.description : description}
            </span>
          </h1>

          <picture
            className="block w-full flex justify-center mx-auto mt-16 px-4"
            style={{ objectFit: 'contain' }}
          >
            <source
              // @ts-ignore
              srcSet={page?.image?.url}
              type="image/webp"
            />
            <Image
              src={page?.image?.url ? page?.image?.url : imageurl}
              alt={page?.image?.figureCaption}
              width={850}
              height={470}
            />
          </picture>
        </div>
      </div>
    </div>
  );
}
