import React from 'react';
import { HoverCardArticle } from 'components/prosjekter/CardManager';
import { PinkTag, StandardWrapper7xl } from 'components/LayoutUtilities';
import Link from 'next/link';
import {
  ButtonLink,
} from 'components/LayoutUtilities';
import Image from 'next/image';

// GraphQL fragment for projectListerQuery
export const Q_ProjectLister = `
  ... on ProjectLister {
    style
    projectSlugs {
      title
      id
      slug
      headline
      heroImage {
        url(transformation: { image: { resize: { fit: crop, height: 800, width: 800 } }, document: { output: { format: webp } } })
      }
      ogData {
        description
        tags
        title
        image {
          url
        }
      }
    }
  }
`;

function Splitter({ project, alignLeft }) {
  return (
    <Link href={'/prosjekter/' + project.slug} legacyBehavior>
      <div className="relative bg-background-card cursor-pointer mb-8 rounded-2xl">
        <Image
          className={`h-56 w-full bg-background-card object-cover rounded-2xl lg:absolute lg:inset-y-0 ${
            !alignLeft ? 'lg:left-0' : 'lg:right-0'
          } lg:h-full lg:w-1/2`}
          src={
            project?.heroImage?.url
              ? project?.heroImage?.url
              : project?.ogData?.image?.url
          }
          width={800}
          height={800}
          alt="Hero image"
        />
        <div className="mx-auto grid lg:grid-cols-2">
          <div
            className={`px-6 py-8 ${
              !alignLeft ? 'lg:col-start-2' : 'lg:col-start-1'
            } lg:px-8`}
          >
            <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
              <h3 className="text-h3 text-text-primary">
                {project?.headline}
              </h3>
              <div className="gap-2 flex mt-2">
                {project?.ogData?.tags.map(tag => (
                  <PinkTag key={tag + 'lul'} tag={tag} />
                ))}
              </div>
              <p className="mt-6 text-text-primary">
                {project?.ogData?.description}
              </p>

              <div className="mt-4">
                <span className="text-link underline hover:no-underline cursor-pointer">
                <ButtonLink
                      link={'/prosjekter/' + project.slug}
                      text={'Se vårt prosjekt med ' + project.title}
                      style="pinkborder"
                      />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

function ThreeByThree({ section }) {
  return (
      <div className="mx-auto py-6 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-4">
        <div className="grid grid-cols-2 gap-8 lg:grid-cols-3 mt-8">
          {section.projectSlugs.map(project => (
            <HoverCardArticle
              key={project.id}
              card={{
                link: '/prosjekter/' + project.slug,
                image: project?.heroImage?.url
                  ? project?.heroImage?.url
                  : project?.ogData?.image?.url,
                heading: project.title,
                date: null,
                title: project?.headline,
                tags: project?.ogData?.tags.join(' '),
              }}
            />
          ))}
        </div>
      </div>
  );
}

function VerticalLister({ section }) {
  return (
      <StandardWrapper7xl>
        {section.projectSlugs.map((project, index) => (
          <Splitter key={project.id} project={project} alignLeft={index % 2} />
        ))}
      </StandardWrapper7xl>
  );
}

export default function ProjectLister({ section }) {
  switch (section.style) {
    case 'verticallandingpage':
      return <VerticalLister section={section} />;
    case 'threebythree':
      return <ThreeByThree section={section} />;
    default:
      return (
        <div>
          Hey you forgot to specify either verticallandingpage, or threebythree.
          Please do so in the CMS.
        </div>
      );
  }
}
