import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import React from 'react';
import Obfuscate from 'react-obfuscate';

// GraphQL fragment for SectionWithImageAndShortParagraph
export const Q_SectionWithImageAndShortParagraph = `
  ... on SectionWithImageAndShortParagraph {
    id
    title
    description {
      raw
    }
    careerSection {
      employees {
        id
        name
        role
        description {
          raw
        }
        avatar {
          url(transformation: { document: { output: { format: webp } } })
          figureCaption
        }
        contactInfo {
          linkedIn
          email
        }
      }
    }
  }
`;

export default function SectionWithImageAndShortParagraph({ section }) {
  return (
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-h2 text-text-primary">
              {section?.title}
            </h2>
            {section.description ? (
              <div className="text-text-primary">
                <RichText content={section?.description?.raw} />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="lg:col-span-2">
            <ul
              role="list"
              className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
            >
              {section?.careerSection?.employees?.map(person => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <div className="aspect-w-2 aspect-h-2">
                      <img
                        className="rounded-2xl object-cover shadow-lg"
                        src={person?.avatar?.url}
                        alt={person?.avatar?.figureCaption}
                      />
                    </div>
                    <div className="space-y-1">
                      <h4 className="text-h4">{person.name}</h4>
                      <p className="text-tertiary">{person.role}</p>
                    </div>
                    <div className="text-lg">
                      {person?.description ? (
                        <div className="text-text-primary">
                          <RichText content={person.description?.raw} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <ul role="list" className="flex space-x-5">
                      {person.contactInfo?.linkedIn && (
                        <li className="text-text-link">
                          <Link
                            href={person.contactInfo?.linkedIn}
                            target="_blank"
                            rel="noreferrer"
                            className="text-text-link cursor-pointer"
                            legacyBehavior
                          >
                            <div>
                              <span className="sr-only">LinkedIn</span>
                              <svg
                                className="h-5 w-5"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          </Link>
                        </li>
                      )}
                      <li className="text-text-link underline hover:no-underline">
                        <Obfuscate
                          email={person?.contactInfo?.email}
                          headers={{
                            subject: 'Mail fra Ilder.no',
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  );
}
