import React from 'react';
export function AnimationBarSubHero() {
  return (
    <div>
      <div className="-top-16 absolute h-96 -ml-16 border-l border-dotted border-ilder-pink"></div>
      <div
        className="absolute h-16 -ml-16"
        data-aos="slide-up"
        data-aos-easing="ease-in-out"
        data-aos-delay="1000"
        data-aos-duration="1000"
      >
        <div className="border-r-8 rounded-r-full border-ilder-pink w-2 h-4 text-text-primary">
          .
        </div>
      </div>
    </div>
  );
}

export default AnimationBarSubHero;
