import { RichText } from '@graphcms/rich-text-react-renderer';
import Link from 'next/link';
import React from 'react';
import Image from 'next/image';

function ButtonLink({ button }) {
  let link = '/';
  let text = '';

  button.buttonText ? (text = button.buttonText) : '';
  button.title ? (text = button.title) : '';

  button?.dynamicPage?.slug ? (link = button.dynamicPage.slug) : '';
  button.link ? (link = button.link) : '';

  return (
    <span className="hover:cursor-pointer bg-ilder-pink -ml-2 rounded-full px-4 py-2 text-white text-lg ">
      <Link href={link} legacyBehavior>{text}</Link>
    </span>
  );
}

export default function SideBySide({ section }) {
  return (
    <div
      className={`py-6 ${
        section.showBlueBackground || section.background == 'dark'
          ? 'bg-ilder-darkerblue'
          : 'bg-white'
      }`}
    >
      <div
        className={`md:grid md:grid-cols-2  pt-12 pb-12 max-w-5xl mx-8 my-auto lg:mx-auto `}
      >
        <div
          className={`max-w-sm mb-12 md:mb-0 ${
            section.showImageOnRight ? 'order-1' : 'order-2 md:mx-auto'
          }`}
        >
          <div className="prose">
            <div
              className={`${
                section.showBlueBackground || section.background == 'dark'
                  ? 'text-ilder-pink'
                  : 'text-ilder-pink'
              } font-light text-xl`}
            >
              {section?.topText}
            </div>
            <div
              className={`text-4xl mb-8 ${
                section.showBlueBackground || section.background == 'dark'
                  ? 'text-white'
                  : 'text-ilder-purple'
              }`}
            >
              {section?.title}
            </div>
            <div
              className={`prose text-lg ${
                section.showBlueBackground || section.background == 'dark'
                  ? 'text-white'
                  : 'text-black'
              }`}
            >
              <RichText content={section?.description?.raw} />
            </div>
          </div>
          <div className="mt-4">
            {section?.button ? <ButtonLink button={section.button} /> : ''}
          </div>
        </div>
        <div
          className={` my-auto mx-auto  ${
            section.showImageOnRight || section.imageAlign == 'right'
              ? 'order-2'
              : 'order-1'
          }`}
        >
          <div>
            <div className="my-auto flex align-middle ">
              <Image
                src={section?.image?.url}
                alt={section?.image?.figureCaption}
                width={560}
                height={400}
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
