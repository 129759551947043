import { RichText } from '@graphcms/rich-text-react-renderer';
import React from 'react';
export default function Location({ section }) {
  return (
    <div className="bg-background py-12">
      <div className="lg:relative lg:z-10">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
          <div
            className={`relative lg:-my-8 ${
              section.showImageOnRight ? 'order-last' : ''
            }`}
          >
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
              <div className="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <img
                  className="g:h-full lg:w-full object-contain"
                  src={section?.image?.url}
                  alt={section?.image?.figureCaption}
                />
              </div>
            </div>
          </div>
          <div className="mt-6 lg:col-span-2 lg:m-0 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                  <h3 className="text-h2 text-text-primary">
                    {section?.title}
                  </h3>
                  <div className="mt-6 text-base text-text-primary">
                    {/* Beskrivelse av plassen, litt om hvordan
                                        dette kontoret har det, etc etc, Lorem
                                        Ipsum */}
                    <RichText content={section?.description?.raw} />
                  </div>
                </div>
                <footer className="mt-6">
                  {section?.location.map((string, id) => (
                    <p
                      key={id}
                      className="text-base font-medium text-text-primary"
                    >
                      {string}
                    </p>
                  ))}
                  {/* <p className="text-base font-medium text-ilder-purple">
                                        (gatenavn placeholder Bergen) Christian
                                        Michelsens gate 6b
                                    </p>
                                    <p className="text-base font-medium text-ilder-purple">
                                        5003 Bergen, Norway
                                    </p> */}
                </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
