import { RichText } from '@graphcms/rich-text-react-renderer';
import React from 'react';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import Image from 'next/image';

export default function Content({ content }) {
  return (
    <div>
      {content.map((section, index) => (
        <div key={'contentmap_' + index}>
          {section.__typename == 'ParallaxHeader' ? (
            <ParallaxHeader content={section} />
          ) : (
            <div />
          )}
          {section.__typename == 'CenteredDescription' ? (
            <CenteredDescription content={section} />
          ) : (
            <div />
          )}
          {section.__typename == 'SideBySide' ? (
            <SideBySide content={section} />
          ) : (
            <div />
          )}
          {section.__typename == 'CenteredImage' ? (
            <CenteredImage content={section} />
          ) : (
            <div />
          )}
          {section.__typename == 'TextHero' ? (
            <TextHero content={section} />
          ) : (
            <div />
          )}
          {section.__typename == 'CtaComponent' ? (
            <CtaComponent content={section} />
          ) : (
            <div />
          )}
          {section.__typename == 'FullwidthImageWithOverlay' ? (
            section.iWantToUseThisComponent == true ? (
              <FullwidthImageWithOverlay content={section} />
            ) : (
              <div />
            )
          ) : (
            <div />
          )}
          {section.__typename == 'PWidget' ? (
            <PWidget content={section} />
          ) : (
            <div />
          )}
        </div>
      ))}
    </div>
  );
}

export const PWidget = ({ content }) => {
  return (
    <div className="justify-center max-w-7xl mx-auto ">
      <div className="justify-center mx-auto">
        <div className="text-4xl mx-auto justify-center flex text-start mb-4 font-semibold tracking-tight text-ilder-purple sm:text-3xl">
          {content?.wtitle}
        </div>
        <div className="prose flex justify-center mx-auto  mt-4 mb-4 text-lg text-gray-500">
          <RichText content={content.wdescription?.raw} />
        </div>
      </div>
      <div className="mx-auto flex justify-center mt-8">
        <iframe
          className="border"
          src={content?.wlink}
          title="Eviny varme landscape widget"
          scrolling="no"
          frameBorder={0}
          marginWidth={0}
          marginHeight={0}
          width={860}
          height={485}
        ></iframe>
      </div>
    </div>
  );
};

const TextHero = ({ content }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-12 lg:px-8 lg:flex">
      <div className="flex flex-col py-12 whitespace-nowrap">
        <h1 className="text-h1 lg:mt-16">{content?.text}</h1>
        <h2 className="text-h2 text-tertiary">
          {content?.pinkText}
        </h2>
      </div>
      <div className="lg:mt-28 lg:pl-16">
        <div className="text-secondary text-xl leading-10 lg:pr-16">
          <RichText content={content?.description?.raw} />
        </div>
      </div>
    </div>
  );
};

const CtaComponent = ({ content }) => {
  return (
    <div className="max-w-2xl mx-4 md:mx-auto py-16">
      <h2 className="mb-4 text-h2 text-text-priamry">
        <span className="block">{content?.title}</span>
      </h2>
      <div className="mb-4 text-text-secondary text-xl leading-10 text-justify">
        <RichText content={content?.description?.raw} />
      </div>
      <div className="-ml-2">
        <HeaderButton
          buttonColor={content?.contactCta.buttonColor}
          headerButtonText={content?.contactCta.title}
          linkTo={content?.contactCta.link}
        />
      </div>
    </div>
  );
};

import Hvind from 'assets/hvind-exp.png';
import Windmill from 'assets/windmill.png';
let Background = '/public/assets/dark-ocean-wave-background.jpg';

const FullwidthImageWithOverlay = ({ content }) => {
  return (
    <div className="relative mt-80">
      <div className="absolute inset-0">
        <picture className=" w-full h-full object-cover">
          <Image
            src={Background}
            alt="Ocean Wave Background"
            layout="fill"
            objectFit="cover"
          />
        </picture>
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <div className="z-50 mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
          <div className="overflow-hidden lg:aspect-none lg:h-full  -my-80 ">
            <img
              className="object-cover h-full w-full"
              src={Hvind}
              alt="Havvind"
            />
            <img
              className="object-cover lg:h-full lg:w-full"
              src={Windmill}
              alt="Windmill"
            />
          </div>
          <div className="h-80"></div>
        </div>
      </div>
    </div>
  );
};

const ParallaxHeader = ({ content }) => {
  return (
    <ParallaxProvider>
      <ParallaxBanner
        layers={[
          {
            image: content?.image?.url,
            speed: -20,
          },
        ]}
        className="absolute"
      >
        <div className="relative h-128 py-24 inset-0 flex items-center justify-center">
          <h1 className="mx-auto text-center text-h1">
            {content.overlay?.image ? (
              <img src={content?.overlay?.image?.url} alt="Plus Logo" />
            ) : (
              <div />
            )}
            {content.overlay?.title ? (
              <div className="text-h1 text-white">{content.overlay.title}</div>
            ) : (
              <div />
            )}
          </h1>
        </div>
      </ParallaxBanner>
    </ParallaxProvider>
  );
};

export function CenteredDescription({ content }) {
  return (
    <div className="flex sm:pt-12 justify-center">
      <div className="max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-start ">
        <h2 className="text-h2 text-text-primary mx-auto justify-center flex">
          <div className="flex justify-center lg:flex-none grid grid-cols-1 lg:grid-cols-1 space-x-1 lg:space-x-0 text-small">
            <span className="lg:block text-h4 text-secondary">
              {content.coloredHeader?.text || 'Our'}
            </span>
            <span className="lg:block text-text-secondary">
              {' '}
              {content.coloredHeader?.pinkText || 'Client'}
            </span>
          </div>
        </h2>
        <div
          className={`mt-8 lg:mt-0 prose text-lg ${
            content.showPinkDivider ? 'border-l-4 pl-4 border-border' : ''
          } lg:mx-8 lg:px-8 max-w-md`}
        >
          <h2 className="text-h2 text-secondary mb-2">
            <span className="block">
              {content.descriptionBlock?.title || null}
            </span>
          </h2>
          <RichText content={content.descriptionBlock?.description?.raw} />
        </div>
      </div>
    </div>
  );
}

import { HeaderButton } from 'components/LayoutUtilities';

function SideBySide({ content }) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
      <div className="mt-24 mx-auto">
        <div className="mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 ">
          <div
            className={`${
              content.showImageOnRight ? 'order-1' : 'order-2'
            } lg:mx-12 max-w-xl mx-auto flex my-auto`}
          >
            <div className="mt-6">
              <h2 className="text-h2 text-secondary">
                {content.title}
              </h2>
              <div className="mt-4 mb-4 prose text-lg text-text-secondary">
                <RichText content={content.description.raw} />
              </div>
              {content.contactCta ? (
                <HeaderButton
                  buttonColor={content.contactCta.buttonColor}
                  headerButtonText={content.contactCta.title}
                  linkTo={content.contactCta.link}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div
            className={`${
              content.showImageOnRight ? 'order-2' : 'order-1'
            }  flex mx-auto`}
          >
            <div className="mx-auto">
              <Image
                className="mx-auto flex"
                src={content.image?.url}
                width={600}
                height={600}
                objectFit="contain"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CenteredImage({ content }) {
  return (
    <div className="my-24 flex mx-auto justify-center">
      <Image
        src={content.image?.url}
        alt={'Centered image'}
        width={1200}
        height={400}
        objectFit="contain"
      />
    </div>
  );
}
